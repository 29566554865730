import { useContext } from 'react';
import { Spin } from 'antd';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import LoadingContext from './contexts/LoadingContext';
import {appRoutes, authRoutes, redirectAuth} from "./routes/web";
import Authenticate from "./middleware/Authenticate";
import Dashboard from "./pages/Dashboard";
import RequireAuth from "@auth-kit/react-router/RequireAuth";
import Profile from "./pages/user/Profile";
import Security from "./pages/user/Security";
import ErrorNotFound from "./pages/error/ErrorNotFound";
import Statistic from "./pages/admin/Statistic";

function App() {
    const { loading } = useContext(LoadingContext);
  
    return ( 
        <BrowserRouter>
            <Spin size='large' fullscreen={true} spinning={loading}/>
            <Routes>
                {authRoutes.map((value,index) => {
                    return <Route key={index} path={value.path} element={value.element}/>
                })}
                {appRoutes.map((value, index) => {
                    if (value.type === 'item') {
                        return <Route key={index} path={value.path} element={<RequireAuth fallbackPath={redirectAuth}>{value.element}</RequireAuth>}/>
                    } else if (value.type === 'children') {
                        const children =  value.children;
                        return children.map((item, indexChildren) => {
                            return <Route key={index + '-' + indexChildren} path={item.path} element={<RequireAuth fallbackPath={redirectAuth}>{item.element}</RequireAuth>}/>
                        })
                    }
                })}
                <Route path={'/user/profile'} element={<RequireAuth fallbackPath={redirectAuth}>{<Profile/>}</RequireAuth>}/>
                <Route path={'/user/security'} element={<RequireAuth fallbackPath={redirectAuth}>{<Security/>}</RequireAuth>}/>
                <Route path={'*'} element={<ErrorNotFound/>}/>
            </Routes>
        </BrowserRouter>
    );
}

export default App;