import Container from "../../components/Container/Container";
import {Button, Flex, Form, message, Modal, Popconfirm, QRCode, Space, Table, Tag, Typography} from "antd";
import {useContext, useEffect, useState} from "react";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";
import LoadingContext from "../../contexts/LoadingContext";
import {formatDateTime, numberFormat} from "../../helpers/helpers";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAdd, faBan} from "@fortawesome/free-solid-svg-icons";
import Search from "../../components/Search/Search";
import TextCopy from "../../components/TextCopy";
import getWithdrawals from "../../api/admin/withdraw/getWithdrawals";
import {CheckOutlined, QrcodeOutlined} from "@ant-design/icons";
import getImageQrCode from "../../api/admin/withdraw/getImageQrCode";
import updateWithdraw from "../../api/admin/withdraw/updateWithdraw";
import dayjs from "dayjs";

function Withdraw() {
    const [form] = Form.useForm();
    const accessToken = useAuthHeader();
    const {setLoading} = useContext(LoadingContext);
    const [messageApi, contextHolder] = message.useMessage();
    const [dataSource,setDataSource] = useState([]);
    const [tableLoading,setTableLoading] = useState(false);
    const [qrCode,setQrcode] = useState('');
    const [openModal,setOpenModal] = useState(false);
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
        search: {
            createdAt: [dayjs().startOf('day'), dayjs().endOf('day')]
        }
    });

    const items = [
        {
            name: 'createdAt',
            type: 'date'
        }
    ]

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            align: 'center',
            sorter: true
        },
        {
            title: 'Ngân hàng',
            dataIndex: 'bankName'
        },
        {
            title: 'Đối tác',
            dataIndex: 'username',
            align: 'center',
            render: (value) => {
                return <Tag>{value}</Tag>
            }
        },
        {
            title: 'Số tài khoản',
            dataIndex: 'accountNumber',
            align: 'center',
            render: (value) => {
                return (
                    <TextCopy value={value}/>
                )
            }
        },
        {
            title: 'Tên tài khoản',
            dataIndex: 'accountName',
            align: 'center',
            render: (value) => {
                return <Tag>{value}</Tag>
            }
        },
        {
            title: 'Số tiền',
            dataIndex: 'amount',
            sorter: true,
            align: 'center',
            render: (value) => {
                return numberFormat(value);
            }
        },
        {
            title: 'Ghi chú',
            dataIndex: 'note',
            width: '200px',
            align: 'center'
        },
        {
            title: 'Trạng thái',
            dataIndex: 'state',
            align: 'center',
            filters: [
                {
                    text: 'Chờ duyệt',
                    value: 'pending'
                },
                {
                    text: 'Đã duyệt',
                    value: 'processed'
                },
                {
                    text: 'Bị từ chối',
                    value: 'refused'
                }
            ],
            render: (value) => {
                if (value === 'pending') {
                    return <Tag color={'yellow'}>Chờ duyệt</Tag>
                }
                else if (value === "processed") {
                    return <Tag color={'green'}>Đã duyệt</Tag>
                }
                else if (value === "refused") {
                    return <Tag color={'red'}>Bị từ chối</Tag>
                }
            }
        },
        {
            title: 'Thời gian tạo',
            dataIndex: 'createdAt',
            sorter: true,
            align: 'center',
            render: (value) => {
                return formatDateTime(value)
            }
        },
        {
            title: 'Hành động',
            dataIndex: 'operation',
            align: 'center',
            render: (value,record) => {
                if (record.state === "pending") {
                    return (
                        <Space>
                            <Button icon={<QrcodeOutlined/>} onClick={() => handleShowQrCode(record.id)} title={'Mã QR'}/>
                            <Popconfirm
                                title={'Cập nhật đơn rút tiền'}
                                description={'Bạn muốn xác nhận duyệt đơn rút tiền này?'}
                                okText="Yes"
                                cancelText="No"
                                onConfirm={() => handleUpdateState(record.id,"processed")}
                            >
                                <Button icon={<CheckOutlined/>} ghost={true} type={'primary'} title={'Duyệt'}/>
                            </Popconfirm>
                            <Popconfirm
                                title={'Cập nhật đơn rút tiền'}
                                description={'Bạn muốn từ chối đơn rút tiền này và hoàn tiền cho đối tác?'}
                                okText="Yes"
                                cancelText="No"
                                onConfirm={() => handleUpdateState(record.id,"refused")}
                            >
                                <Button icon={<FontAwesomeIcon icon={faBan}/>} danger={true} type={'primary'} title={'Từ chối'}/>
                            </Popconfirm>
                        </Space>
                    )
                }
            }
        }
    ];

    const save =  async (id, data) => {
        try {
            const newData = [...dataSource];
            const index = newData.findIndex((item) => id === item.id);
            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, {
                    ...item,
                    ...data,
                });
                setDataSource(newData);
            } else {
                newData.push(data);
                setDataSource(newData);
            }
        } catch (e) {
            console.log('Validate Failed:', e);
        }
        finally {
        }
    }

    const handleUpdateState = (id,state) => {
        setLoading(true);
        const fetchUpdate = async () => {
            const response = await updateWithdraw(accessToken, id, {
                state
            });
            if (response.success) {
                await save(id, response.data);
            }
        }
        fetchUpdate().finally(() => setLoading(false));
    }

    const handleShowQrCode = (uuid) => {
        setLoading(true);
        const fetchQrCode = async () => {
            const response = await getImageQrCode(accessToken, uuid);
            if (response.success) {
                setOpenModal(true);
                setQrcode(response.data.qrcode);
            }
        }
        fetchQrCode().finally(() => setLoading(false));
    }

    const handleSearch =  () => {
        const params = form.getFieldsValue();
        setTableParams({
            ...tableParams,
            search: {
                ...params
            }
        })
    }

    const fetchDataTable = async () => {
        setTableLoading(true);
        const response = await getWithdrawals(accessToken,tableParams);
        if (response.success) {
            setDataSource(response.data.data);
            setTableParams({
                ...tableParams,
                pagination: {
                    ...tableParams.pagination,
                    total: response.data.total
                },
            });
        }
        setTableLoading(false);
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            ...tableParams,
            pagination,
            filters,
            sorter,
        });
    };

    useEffect(() => {
        fetchDataTable();
    }, [
        tableParams.pagination?.current,
        tableParams.pagination?.pageSize,
        JSON.stringify(tableParams.sorter),
        JSON.stringify(tableParams.search),
        JSON.stringify(tableParams.filters),
    ])

    return (
        <Container>
            <Space
                style={{
                    marginBottom: 16,
                }}
            >

            </Space>
            <Search
                items={items}
                form={form}
                onSearch={handleSearch}
            />
            <Table
                rowKey={'id'}
                columns={columns}
                dataSource={dataSource}
                bordered={true}
                scroll={{
                    x: 1800,
                }}
                loading={tableLoading}
                pagination={tableParams.pagination}
                onChange={handleTableChange}
            />
            <Modal
                title={'Mã QR code chuyển tiền'}
                open={openModal}
                onOk={() => setOpenModal(false)}
                onCancel={() => setOpenModal(false)}
            >
                <Flex
                    justify={'center'}
                    align={'center'}
                >
                    <QRCode value={qrCode}/>
                </Flex>
            </Modal>
        </Container>
    )
}

export default Withdraw;