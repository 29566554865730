import Container from "../components/Container/Container";
import {Button, Flex, Form, Space, Statistic, Table, Tag, Typography} from "antd";
import {useContext, useEffect, useState} from "react";
import Search from "../components/Search/Search";
import getPayIns from "../api/pay-in/getPayIns";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";
import {formatDateTime, numberFormat} from "../helpers/helpers";
import {InfoOutlined} from "@ant-design/icons";
import TextCopy from "../components/TextCopy";
import LoadingContext from "../contexts/LoadingContext";
import getBanks from "../api/bank/getBanks";
import dayjs from "dayjs";



function PayIn() {
    const accessToken = useAuthHeader();
    const [tableLoading,setTableLoading] = useState(false);
    const [form] = Form.useForm();
    const [dataSource,setDataSource] = useState([]);
    const [options, setOptions] = useState([]);
    const { setLoading } = useContext(LoadingContext);
    const [summaryData, setSummaryData] = useState({
        totalAmount: 0,
        totalFee: 0,
        totalActualAmount: 0,
        totalTransactionAmount: 0
    });
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
        search: {
            createdAt: [dayjs().startOf('day'), dayjs().endOf('day')]
        }
    });

    const items =  [
        {
            name: 'uuid',
            placeholder: 'Mã đơn',
            type: 'text'
        },
        {
            name: 'requestId',
            placeholder: 'Mã tra soát',
            type: 'text'
        },
        {
            name: 'code',
            placeholder: 'Mã khớp',
            type: 'text'
        },
        {
            name: 'createdAt',
            placeholder: 'Thời gian',
            type: 'date'
        },
    ]

    const columns = [
        {
            title: 'ID',
            dataIndex: 'uuid',
            align: 'center',
            render: (value) => {
                return <TextCopy value={value}/>
            }
        },
        {
            title: 'Mã tra soát',
            dataIndex: 'requestId',
            width: '150px',
            align: 'center',
            render: (value) => {
                return <TextCopy value={value}/>
            }
        },
        {
            title: 'Ngân hàng',
            dataIndex: 'bankName',
            align: 'center',
            filterMode: 'tree',
            filters: options
        },
        {
            title: 'Tài khoản nhận',
            dataIndex: 'accNo',
            align: 'center',
            render: (value) => {
                return <Tag>{value}</Tag>
            }
        },
        {
            title: 'Mã khớp',
            dataIndex: 'code',
            width: '150px',
            align: 'center',
            render: (value) => {
                return <TextCopy value={value}/>
            }
        },
        {
            title: 'Số tiền tạo',
            dataIndex: 'amount',
            align: 'center',
            sorter: true,
            render: (value) => {
                return numberFormat(value)
            }
        },
        {
            title: 'Thực chuyển',
            dataIndex: 'transactionAmount',
            align: 'center',
            sorter: true,
            render: (value) => {
                return numberFormat(value)
            }
        },
        {
            title: 'Phí',
            dataIndex: 'fee',
            align: 'center',
            render: (value) => {
                return numberFormat(value, 2)
            }
        },
        {
            title: 'Tiền cộng',
            dataIndex: 'actualAmount',
            align: 'center',
            sorter: true,
            render: (value) => {
                return numberFormat(value)
            }
        },
        {
            title: 'Trạng thái',
            dataIndex: 'state',
            align: 'center',
            filters: [
                {
                    text: 'Đang chờ',
                    value: 'pending'
                },
                {
                    text: 'Thành công',
                    value: 'processed'
                },
                {
                    text: 'Lỗi',
                    value: 'failed'
                },
                {
                    text: 'Cảnh báo',
                    value: 'warning'
                }
            ],
            render: (value) => {
                const statusMap = {
                    pending: { color: 'warning', label: 'Đang chờ' },
                    processed: { color: 'success', label: 'Thành công' },
                    failed: { color: 'error', label: 'Chưa thanh toán' },
                    warning: { color: 'warning', label: 'Đang báo cáo'}
                };
                const { color, label } = statusMap[value] || { color: 'default', label: value };
                return <Tag color={color}>{label}</Tag>;
            }
        },
        {
            title: 'Trạng thái CB',
            dataIndex: 'isCallback',
            align: 'center',
            render: (value) => {
                if (value) {
                    return <Tag color={'success'}>Đã CALLBACK</Tag>
                }
                return <Tag color={'yellow'}>Chưa CALLBACK</Tag>
            }
        },
        {
            title: 'Thời gian',
            dataIndex: 'expiredAt',
            align: 'center',
            render: (value,record) => {
                if (record.state !== "pending") {
                    return <Tag color={'success'}>
                        <Statistic.Countdown value={0} valueStyle={{
                            fontSize: '13px'
                        }}/>
                    </Tag>
                }
                const deadline = value * 1000;
                if (Date.now() > deadline) {
                    return <Tag color={'error'}>Hết hạn</Tag>
                }
                return (
                    <Tag color={'warning'}>
                        <Statistic.Countdown value={deadline} valueStyle={{
                            fontSize: '13px'
                        }}/>
                    </Tag>
                )
            }
        },
        {
            title: 'Thời gian tạo',
            dataIndex: 'createdAt',
            sorter: true,
            align: 'center',
            width: '180px',
            render: (value,record)  => {
                return (
                    <Tag>{formatDateTime(value)}</Tag>
                )
            }
        },
        {
            title: 'Thời gian hoàn thành',
            dataIndex: 'completedAt',
            sorter: true,
            align: 'center',
            width: '180px',
            render: (value,record)  => {
                if (value) {
                    return (
                        <Tag>{formatDateTime(value)}</Tag>
                    )
                }
            }
        },
        {
            title: 'Hành động',
            dataIndex: 'operation',
            align: 'center',
            render: (value,record) => {
                return (
                    <Space>
                        {record.state === 'failed' && <Button danger={true} type={'primary'} title={'Giao dịch đã chuyển nhưng báo lỗi hoặc hết hạn'}>Báo cáo</Button>}
                    </Space>
                )
            }
        }
    ];
    const handleSearch = () => {
        const params = form.getFieldsValue();
        setTableParams({
            ...tableParams,
            search: {
                ...params
            }
        })
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            ...tableParams,
            pagination,
            filters,
            sorter,
        });
    };

    const fetchDataTable = async () => {
        setTableLoading(true);
        const response = await getPayIns(accessToken,tableParams);
        if (response.success) {
            setDataSource(response.data.data);
            setTableParams({
                ...tableParams,
                pagination: {
                    ...tableParams.pagination,
                    total: response.data.total
                },
            });
            setSummaryData({
                totalAmount: response.data.totalAmount,
                totalFee: response.data.totalFee,
                totalActualAmount: response.data.totalActualAmount,
                totalTransactionAmount: response.data.totalTransactionAmount
            })
        }
        setTableLoading(false);
    }

    useEffect(() => {
        setLoading(true);
        const fetchBanks = async () => {
            const response = await getBanks(accessToken);
            if (response.success) {
                setOptions(response.data.map(item => ({
                    text:  item.displayName,
                    value: item.bankName
                })))
            }
        }
        fetchBanks().finally(() => setLoading(false));
    }, []);

    useEffect(() => {
        fetchDataTable();
    }, [
        tableParams.pagination?.current,
        tableParams.pagination?.pageSize,
        tableParams?.sorter,
        tableParams?.search,
        JSON.stringify(tableParams.filters),
    ]);

    return (
        <Container>
            <Search
                items={items}
                form={form}
                onSearch={handleSearch}
            />
            <Table
                style={{
                    fontWeight: 500
                }}
                rowKey={'uuid'}
                columns={columns}
                scroll={{
                    x: 2200,
                }}
                loading={tableLoading}
                bordered={true}
                dataSource={dataSource}
                pagination={tableParams.pagination}
                onChange={handleTableChange}
                summary={(data) => {
                    return (
                        <Table.Summary.Row>
                            <Table.Summary.Cell index={0} colSpan={5}>
                                Thống kê
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={1} align={'center'} colSpan={1}>
                                <Tag color={'blue'}>{numberFormat(summaryData.totalAmount)}</Tag>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={2} align={'center'} colSpan={1}>
                                <Tag color={'blue'}>{numberFormat(summaryData.totalTransactionAmount)}</Tag>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={1} align={'center'} colSpan={1}>
                                <Tag color={'blue'}>{numberFormat(summaryData.totalFee)}</Tag>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={2} align={'center'} colSpan={1}>
                                <Tag color={'blue'}>{numberFormat(summaryData.totalActualAmount)}</Tag>
                            </Table.Summary.Cell>
                        </Table.Summary.Row>
                    );
                }}
            />
        </Container>
    )
}

export default PayIn;